import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Container, Card } from "react-bootstrap";
// import Image from "components/Image";
import "./ImageCard.scss";
import Video from "../../content/assets/videos/pixta_29486102_HD_1080.mp4"

const ImageCard = ({ className, imageFileName, imageAlt, header, subheader, extraInfo }) => {
  return (
    <Card className={clsx("image-card bg-dark text-grandiflourus text-center", className)}>
    {/* <Image className="image" fileName={imageFileName} alt={imageAlt || header || subheader} /> */}
      <video autoPlay loop muted playsInline controls fluid className="video">
        <source src={ Video } />
      </video>
      <Card.ImgOverlay className="no-padding mt10">
        <Container>
          <div className="intro-text">
            <div className="intro-lead-in">{subheader}</div>
            <div className="intro-heading text-uppercase">{header}</div>
            {extraInfo}
          </div>
        </Container>
      </Card.ImgOverlay>
    </Card>
  );
};

ImageCard.propTypes = {
  className: PropTypes.string,
  imageFileName: PropTypes.string,
  imageAlt: PropTypes.string,
  header: PropTypes.string,
  subheader: PropTypes.string,
  extraInfo: PropTypes.any,
};

ImageCard.defaultProps = {
  className: null,
  imageFileName: null,
  imageAlt: null,
  header: "",
  subheader: "",
  extraInfo: null,
};

export default ImageCard;
