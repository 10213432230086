/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react"
import "./contactForm.scss"
import SectionHeader from "./SectionHeader"

const functionURL = "https://aqua-emu-6094.twil.io/send-email" // replace this with your function URL

class ContactForm extends React.Component {
 constructor(props) {
   super(props)
   this.state = {
     // eslint-disable-next-line react/no-unused-state
     buttonDisabled: true,
     message: { inquiryType: "", customerName: "", fromEmail: "", body: "" },
     submitting: false,
     error: null,
   }
 }

 onClick = async event => {
   event.preventDefault()
   this.setState({ submitting: true })
   const thisState = this.state
   const { inquiryType, customerName, fromEmail, body } = thisState.message

   const response = await fetch(functionURL, {
     mode: "cors",
     method: "post",
     headers: {
       "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
     },
     body: new URLSearchParams({ inquiryType, customerName, fromEmail, body }).toString(),
   })
   if (response.status === 200) {
     this.setState({
       error: null,
       submitting: false,
       message: {
         inquiryType: "",
         customerName: "",
         fromEmail: "",
         body: "",
       },
     })
   } else {
    this.setState({
      error: null,
      submitting: false,
      message: {
        inquiryType: "",
        customerName: "",
        fromEmail: "",
        body: "",
      },
    })
     const json = await response.json()
     this.setState({
       error: json.error,
       submitting: false,
     })
   }
 }

 onChange = event => {
   const name = event.target.getAttribute("name")
   this.setState( prevState => ({
     message: { ...prevState.message, [name]: event.target.value },
   }))
 }

 render() {
   const thisState = this.state;
   return (
     <>
       <div>{thisState.error}</div>
       <SectionHeader header="お問い合わせ" />
       <form
         method="post"
         action={functionURL}
       >
         お問い合わせ内容
         <div className="inquiryType">
           <div className="">
            <input
              className="input_radio"
              type="radio"
              id="inquiryType1"
              name="inquiryType"
              value="デジタルマーケティング・研修のご依頼"
              onChange={this.onChange}
            />
            <label htmlFor="inquiryType1">デジタルマーケティング・研修のご依頼</label>
          </div>
          <div>
            <input
              className="input_radio"
              type="radio"
              id="inquiryType2"
              name="inquiryType"
              value="シェアハウスについて"
              onChange={this.onChange}
            />
            <label htmlFor="inquiryType2">シェアハウスについて</label>
          </div>
          <div>
            <input
              className="input_radio"
              type="radio"
              id="inquiryType3"
              name="inquiryType"
              value="CoderDojoについて"
              onChange={this.onChange}
            />
            <label htmlFor="inquiryType3">CoderDojoについて</label>
          </div>
          <div>
            <input
              className="input_radio"
              type="radio"
              id="inquiryType4"
              name="inquiryType"
              value="動画配信事業について"
              onChange={this.onChange}
            />
            <label htmlFor="inquiryType4">動画配信事業について</label>
          </div>
          <div>
            <input
              className="input_radio"
              type="radio"
              id="inquiryType5"
              name="inquiryType"
              value="その他"
              onChange={this.onChange}
            />
            <label htmlFor="inquiryType5">その他</label>
          </div>
        </div>
          <label htmlFor="customerName">お名前</label>
          <input
            type="text"
            name="customerName"
            id="customerName"
            value={thisState.message.customerName}
            onChange={this.onChange}
          />
          <label htmlFor="fromEmail">メールアドレス</label>
          <input
            type="email"
            name="fromEmail"
            id="fromEmail"
            value={thisState.message.fromEmail}
            onChange={this.onChange}
          />
          <label htmlFor="body">本文</label>
          <textarea
            name="body"
            id="body"
            value={thisState.message.body}
            onChange={this.onChange}
          />
          <button
            className="btn-primary"
            type="submit"
            disabled={thisState.submitting}
            onClick={this.onClick}
          >
            メッセージを送信する
          </button>
       </form>
     </>
   )
 }
}

export default ContactForm