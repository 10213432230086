import React from "react";
import PropTypes from "prop-types";

import { Container, Row, Col } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image"
import * as SocialIcons from "components/SocialIcons";

const Footer = ({ frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const {
    brand,
    address1,
    address2,
    telephone,
    email,
    copyright,
    privacyHref,
    privacyText,
    termsHref,
    termsText,
    social: { facebook, github, linkedin, medium, twitter },
  } = frontmatter;

  return (
    <footer className="footer mt-5 py-3 text-white bg-dark">
      <Container>
        {/* 会社概要を画像にする場合 */}
        {/* <Row className="align-items-center text-center">
          <Col lg={12}>
            <StaticImage
              src="../../../content/assets/images/about/hict_about.png"
              alt="about the corpolation"
              style={{
              }}
            />
          </Col>
        </Row> */}
        <Row className="align-items-center text-center h4">
          <Col lg={6}>
            {brand}
          </Col>
        </Row>
        <Row className="pl-20 align-items-center text-center">
          <Col lg={6}>
            {address1}<br />
            {address2}
          </Col>
          <Col lg={3}>
            {telephone}
          </Col>
          <Col lg={3}>
            {email}
          </Col>
        </Row>
        <Row className="align-items-center text-center">
          <Col lg={5} className="text-lg-left">
            {copyright}
          </Col>
          <Col lg={3} className="my-3 my-lg-0">
            {twitter ? <SocialIcons.Twitter userName={twitter} /> : null}
            {facebook ? <SocialIcons.Facebook userName={facebook} /> : null}
            {linkedin ? <SocialIcons.Linkedin userName={linkedin} /> : null}
            {github ? <SocialIcons.Github userName={github} /> : null}
            {medium ? <SocialIcons.Medium userName={medium} /> : null}
          </Col>
          <Col lg={4} className="text-lg-right">
            <a className="mr-3" href={privacyHref}>
              {privacyText}
            </a>
            <a href={termsHref}>{termsText}</a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

Footer.propTypes = {
  frontmatter: PropTypes.object,
};

Footer.defaultProps = {
  frontmatter: null,
};

export default Footer;
